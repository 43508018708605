import {updateJson5State} from '../../../reducers/configurationReducer'
import {useAppDispatch} from '../../../store'

const TimeConfig = ({state}: any) => {
  const dispatch = useAppDispatch()
  const handleTimeChange = (e: any) => {
    const time = e.target.value
    const [hour] = time.split(':')
    const newValue = [`${hour}:00`]
    dispatch(updateJson5State(newValue))
  }

  return (
    <div className=''>
      <input
        type='time'
        className='form-control'
        onChange={handleTimeChange}
        value={state?.value?.[0]}
      />
    </div>
  )
}

export default TimeConfig
