import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchIconAction} from '../../../reducers/iconReducer'
import {fetchServices, serviceOneSelector, statusSelector} from '../../../reducers/servicesReducer'
import {AppDispatch} from '../../../store'
import ServiceCard from '../components/ServiceCard'
import ServicesLayout from '../ServicesLayout'
import ServiceLoading from '../components/ServiceLoading'
import {KTSVG} from '../../../../_metronic/helpers'

const ServicesOne = () => {
  const serviceData = useSelector(serviceOneSelector)
  const status = useSelector(statusSelector)
  const dispatch = useDispatch<AppDispatch>()

  const [isActive, setIsActive] = useState(true)

  const [admin, setAdmin] = React.useState(true)
  const [notAdmin, setNotAdmin] = React.useState(false)
  const [both, setBoth] = React.useState(false)

  const [sort, setSort] = useState(0)
  const [serviceType, setServiceType] = useState(2)

  useEffect(() => {
    dispatch(fetchIconAction())
  }, [])

  const toggleSort = () => {
    setSort((prevSort) => (prevSort + 1) % 3)
  }

  useEffect(() => {
    dispatch(fetchServices({level: 1, parentId: null}))
  }, [])

  const sortData = (data: any[], sortType: string) => {
    return data.sort((a, b) => a?.name.localeCompare(b?.name) * (sortType === 'asc' ? 1 : -1))
  }

  function checkFilter() {
    let filteredData = [...serviceData]
    if (serviceType !== 2) {
      filteredData = filteredData.filter(
        (f) => f.serviceIdentifier.type === (serviceType === 0 ? 'service' : 'content')
      )
    }

    if (admin && isActive) {
      return filteredData.filter((f) => f.isAdmin === 1 && f.isActive)
    } else if (admin && !isActive) {
      return filteredData.filter((f) => f.isAdmin === 1 && !f.isActive)
    } else if (notAdmin && isActive) {
      return filteredData.filter((f) => f.isAdmin !== 1 && f.isActive)
    } else if (notAdmin && !isActive) {
      return filteredData.filter((f) => f.isAdmin !== 1 && !f.isActive)
    } else if (both && isActive) {
      return filteredData.filter((f) => f.isActive)
    } else if (both && !isActive) {
      return filteredData.filter((f) => !f.isActive)
    } else {
      return filteredData
    }
  }

  const SortedServiceData = useMemo(() => {
    let data

    if (admin || notAdmin || both || isActive) {
      data = checkFilter()
    } else {
      data = checkFilter()
    }
    switch (sort) {
      case 1:
        data = sortData([...data], 'asc')
        break
      case 2:
        data = sortData([...data], 'desc')
        break
      default:
        data = data
    }
    return data
  }, [sort, serviceData, admin, notAdmin, both, isActive, serviceType])

  return (
    <ServicesLayout>
      <div className='d-flex justify-content-end mt-n8 mb-3 dropdown'>
        <div
          onClick={toggleSort}
          className={`btn btn-sm btn-icon btn-color-white border-0 me-3 ${
            sort ? 'bg-primary' : 'bg-secondary'
          }`}
        >
          <img
            style={{filter: 'brightness(200 %) saturate(0%)'}}
            src={`${sort == 2 ? '/media/ztoa.png' : '/media/atoz.png'}`}
            alt='icon'
            className='my-1'
          />
        </div>
        <div
          style={{cursor: 'auto'}}
          className='btn btn-sm btn-icon btn-color-white border-0 me-3 bg-primary'
        >
          {SortedServiceData?.length}
        </div>
        <div>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-color-white border-0 me-n3 bg-primary'
            id='dropdownMenuButton2'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <ul
            className='dropdown-menu dropdown-menu-light w-250px w-md-300px'
            aria-labelledby='dropdownMenuButton2'
          >
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 pt-5'>
              <div className='mb-10'>
                <label className='form-label fw-bold mb-4'>Services created by:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setNotAdmin(true)
                        setAdmin(false)
                        setBoth(false)
                      }}
                      checked={notAdmin ? true : false}
                    />
                    <span className='form-check-label'>Pro</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={() => {
                        setAdmin(true)
                        setNotAdmin(false)
                        setBoth(false)
                      }}
                      checked={admin ? true : false}
                    />
                    <span className='form-check-label'>Admin</span>
                  </label>
                  <label className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={() => {
                        setBoth(true)
                        setAdmin(false)
                        setNotAdmin(false)
                      }}
                      checked={both ? true : false}
                    />
                    <span className='form-check-label'>Both</span>
                  </label>
                </div>
                <label className='form-label fw-bold mt-6'>Services status:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setIsActive(true)
                      }}
                      checked={isActive ? true : false}
                    />
                    <span className='form-check-label'>Active</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setIsActive(false)
                      }}
                      checked={!isActive ? true : false}
                    />
                    <span className='form-check-label'>InActive</span>
                  </label>
                </div>
                <label className='form-label fw-bold mt-6'>Services type:</label>
                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setServiceType(2)
                      }}
                      checked={serviceType === 2 ? true : false}
                    />
                    <span className='form-check-label'>All</span>
                  </label>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setServiceType(0)
                      }}
                      checked={serviceType === 0 ? true : false}
                    />
                    <span className='form-check-label'>Service</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      onChange={(e) => {
                        setServiceType(1)
                      }}
                      checked={serviceType === 1 ? true : false}
                    />
                    <span className='form-check-label'>Content</span>
                  </label>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className='row mt-5'>
        {status === 'loading' ? (
          <ServiceLoading />
        ) : (
          SortedServiceData?.map((ser: any, idx: number) => <ServiceCard key={idx} data={ser} />)
        )}
      </div>
    </ServicesLayout>
  )
}

export default ServicesOne
