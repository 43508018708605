import React, {useEffect} from 'react'
import {useAppDispatch} from '../../store'
import {
  fetchServiceIdentifiers,
  selectIdentifier,
  serviceIdentifierSelector,
  serviceIdentifiersSelector,
} from '../../reducers/servicesReducer'
import {useSelector} from 'react-redux'
import InfoWrapper from '../../modules/common/SectionInfo'

const ServiceIdentifiers = () => {
  const dispatch:any = useAppDispatch()
  const [id, setId] = React.useState(1)

  const identifiers = useSelector(serviceIdentifiersSelector)
  const singleIdentifier = useSelector(serviceIdentifierSelector)

  useEffect(() => {
    dispatch(fetchServiceIdentifiers())
  }, [])

  const handleChange = (e: any) => {
    // e.preventDefault()
    setId(e.target.value)

    if (e.target.value) {
      dispatch(selectIdentifier(e.target.value))
    } else {
      dispatch(selectIdentifier(null))
    }
  }

  return (
    <>
      <div className='row mb-5 mt-n5 pt-n5 align-items-center'>
        <div className='col-6'>
          <h2 className='py-3 mb-0'>Service identifiers</h2>
        </div>
      </div>
      <div className='card py-5 px-8'>
        <div className='row'>
          <div className='col-12     text-start px-0 d-flex align-items-center'>
            <select className='form-select form-select-lg' onChange={handleChange} value={id}>
              {identifiers?.map((identifier) => (
                <option key={identifier?.id} value={identifier?.id}>
                  {identifier?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <InfoWrapper>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='fw-bold text-muted mb-2'>Pro allowed</div>
              <p className='fs-6 mb-7 fw-bold'>
                {singleIdentifier?.isProAllowed === 1 ? 'Yes' : 'No'}
              </p>
            </div>
            {singleIdentifier?.identifiers?.length !== 0 ? (
              singleIdentifier?.identifiers?.map((identifier: any, idx: number) => (
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Identifier {idx + 1}</div>
                  <p className='fs-6 mb-7 fw-bold'>{identifier}</p>
                </div>
              ))
            ) : (
              <div>No service identifiers found</div>
            )}
          </div>
        </InfoWrapper>
      </div>
    </>
  )
}

export default ServiceIdentifiers
