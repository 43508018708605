import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CgPathDivide} from 'react-icons/cg'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {SubmitHandler, useController, useForm} from 'react-hook-form'
import {AppDispatch} from '../../../store'
import {addServices, getAllServices} from '../../../../api/endpoints/service'
import {SHOW_SUCCESS} from '../../../../util'
import {MuiTooltip} from '../../../common/Tooltip'
import {fetchServices} from '../../../reducers/servicesReducer'

interface FormFields {
  icon: string
  isMemberDefault: 1 | 0
  name: string
  searchAbleId: string
}

const AddNewSubChannel = ({
  open,
  setOpen,
  libraryIcon,
  parents,
  searchAbleId,
  setLibraryIcons,
  setParents,
}: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const {Id} = useParams<{Id: string}>()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {errors},
  } = useForm<FormFields>({
    defaultValues: {
      icon: '',
      name: '',
      searchAbleId: '',
    },
  })

  const {field} = useController({name: 'searchAbleId', control})

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const identifier = parents.find((item: any) => item.id == field.value)

    const finalData = {
      ...data,
      level: '2',
      isAdmin: '1',
      parentId: field.value,
      serviceIdentifierId: identifier?.serviceIdentifier?.id,
    }
    const res: any = await addServices(finalData)
    const {success, message} = res.data
    if (success) {
      SHOW_SUCCESS(success, 'Sub Channel Created  successfully !')
      dispatch(fetchServices({level: 2, parentId: field.value}))
      setOpen({...open, SubChannelModel: false})
    }
  }

  useEffect(() => {
    setValue('icon', libraryIcon)
    setValue('searchAbleId', searchAbleId)
  }, [libraryIcon, searchAbleId])
  return (
    <>
      <div className='modal-header'>
        {/* begin::Modal title */}
        <h2 className='fw-bolder text-white'>Add New Sub Channel</h2>
        {/* end::Modal title */}

        {/* begin::Close */}
        <div
          onClick={() => {
            setOpen({...open, SubChannelModel: false})
          }}
          className='btn btn-icon btn-sm btn-active-icon-primary'
          style={{cursor: 'pointer'}}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
        {/* end::Close */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='text-center m-5'>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Channel</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              {...register('searchAbleId', {required: 'Channel is required'})}
            >
              <option value=''>Select a Channel...</option>
              {parents
                ?.filter((f: any) => f?.parentId === null)
                .map((u: any) => (
                  <option value={u?.id}>{u?.name}</option>
                ))}
            </select>
            {errors.searchAbleId?.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.searchAbleId?.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Sub Channel</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <input
              {...register('name', {required: 'Sub Channel Name is required'})}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Sub Channel'
            />
            {errors.name?.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name?.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Icon</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <div className='d-flex align-items-center'>
              <input
                type='text'
                {...register('icon', {required: 'Icon is required'})}
                className='form-control form-control-lg form-control-solid'
                placeholder='Icon Link'
              />
              <MuiTooltip title='Add Icon from Library'>
                <div
                  onClick={() => setOpen({...open, iconModel: true})}
                  className='text-center m-auto ms-2'
                >
                  <CgPathDivide
                    color='white'
                    className='me-3 pointer'
                    style={{color: 'white'}}
                    size={'2rem'}
                  />
                </div>
              </MuiTooltip>
            </div>
            {errors.icon?.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.icon?.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <button type='submit' className='btn btn-primary mt-4' aria-label='submit data'>
          Submit
        </button>
      </form>
    </>
  )
}

export default AddNewSubChannel
