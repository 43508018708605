import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CgPathDivide} from 'react-icons/cg'
import {SubmitHandler, useController, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../../store'
import {
  fetchServices,
  serviceIdentifiersSelector,
  updateServicesAction,
} from '../../../reducers/servicesReducer'
import {addServices, getAllServices} from '../../../../api/endpoints/service'
import {SHOW_SUCCESS} from '../../../../util'
import {MuiTooltip} from '../../../common/Tooltip'

interface FormFields {
  icon: string
  isMemberDefault: 1 | 0
  serviceType: boolean
  isMcc: any
  name: string
  serviceIdentifierId: number
}

const AddNewChannel = ({open, setOpen, libraryIcon}: any) => {
  const regex = /[a-zA-Z0-9\-_]+/

  const dispatch = useDispatch<AppDispatch>()
  const {Id} = useParams<{Id: string}>()

  const identifiers = useSelector(serviceIdentifiersSelector)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {errors},
  } = useForm<FormFields>({
    defaultValues: {
      icon: '',
      isMemberDefault: 0,
      isMcc: '',
      name: '',
      serviceIdentifierId: 1,
    },
  })

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const finalData = {
      ...data,
      isMemberDefault: data.isMemberDefault ? 1 : 0,
      level: '1',
      isAdmin: '1',
      isActive: data.isMemberDefault ? 1 : 0,
      parentId: null,
    }
    if (Id) {
      dispatch(updateServicesAction({data: finalData, id: Id}))
    } else {
      const res: any = await addServices(finalData)
      const {data, success, message} = res.data
      if (success) {
        SHOW_SUCCESS(success, 'Channel created  successfully !')

        setOpen({...open, channelModel: false})
      }
    }
    dispatch(fetchServices({level: 1, parentId: null}))
  }

  useEffect(() => {
    setValue('icon', libraryIcon)
  }, [libraryIcon])

  return (
    <>
      <div className='modal-header'>
        <h2 className='fw-bolder text-white'>Add New Channel</h2>
        <div
          onClick={() => {
            setOpen({...open, channelModel: false})
          }}
          className='btn btn-icon btn-sm btn-active-icon-primary'
          style={{cursor: 'pointer'}}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
        {/* end::Close */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='text-center m-5'>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Channel</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <input
              {...register('name', {required: 'Channel Name is required'})}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Channel Name'
            />
            {errors.name?.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name?.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Service identifier</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <select
              {...register('serviceIdentifierId')}
              className='form-select form-select-lg form-select-solid'
            >
              {identifiers?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-sm-3 col-form-label fw-bold fs-6'>
            <span className='required'>Icon</span>
          </label>

          <div className='col-sm-9 fv-row'>
            <div className='d-flex align-items-center'>
              <input
                {...register('icon', {required: 'Icon is required'})}
                type='url'
                className='form-control form-control-lg form-control-solid'
                placeholder='Icon Link'
              />
              <MuiTooltip title='Add Icon from Library'>
                <div
                  onClick={() => setOpen({...open, iconModel: true})}
                  className='col-lg-1 text-center m-auto ms-2'
                >
                  <CgPathDivide color='white' className='me-3 pointer' size={'2rem'} />
                </div>
              </MuiTooltip>
            </div>
            {errors.icon?.type === 'required' && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.icon?.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>is Member Default</label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check form-switch fv-row'>
              <input
                {...register('isMemberDefault', {required: false})}
                className={`form-check-input w-45px h-30px`}
                type='checkbox'
                id='isMemberDefault'
              />
              <label className='form-check-label'></label>
            </div>
          </div>
        </div>
        <div className='row mb-4 text-start'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            Confirm that MCC codes are added to the channel
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check form-switch fv-row'>
              <input
                {...register('isMcc', {required: true})}
                className={`form-check-input w-45px h-30px`}
                type='checkbox'
                id='isMcc'
              />
              <label className='form-check-label'></label>
            </div>
          </div>
          {errors.isMcc?.type && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>Required</span>
              </div>
            </div>
          )}
        </div>
        <button type='submit' className='btn btn-primary mt-4' aria-label='submit data'>
          Submit
        </button>
      </form>
    </>
  )
}

export default AddNewChannel
